.monthly-europe-summary-table {
    height: 330px;
    width: 820px;
}

.monthly-europe-summary-table > div {
    border: none !important;
}

.monthly-europe-summary-table .MuiDataGrid-columnHeaderWrapper{
    overflow: unset !important;
}

.monthly-europe-summary-table .header-row {
    background-color: #454545;
    color: white;
}

/* data rows */
.monthly-europe-summary-table .odd-row {
    background-color: inherit;
}

.monthly-europe-summary-table .even-row {
    background-color: #e8f4f8;
}

/* data cells */
.monthly-europe-summary-table .edited-cell {
    background-color: #eeeeac;
}

.monthly-europe-summary-table .manuel-forecast-cell {
    background-color: #e6c79a;
}

/* overalls */
.monthly-europe-summary-table .overall-row {
    font-weight: 600;
}

.monthly-europe-summary-table .overall-injection-row {
    font-weight: 600;
    background-color: #ffd0d0;
}

.monthly-europe-summary-table .overall-winter-row,
.monthly-europe-summary-table .overall-winter-years-row {
    font-weight: 600;
    background-color: #add8e6;
}
